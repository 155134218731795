import React from 'react'
import styled from '@emotion/styled'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import { mobile } from '@/utils/media'
import { mq } from '@/utils/helpers'
import { getThemeColor } from '@/utils/themes'
import Typography from '@/components/common/Typography'
import Tag from '@/components/common/Tag'
import Icon from '@/components/common/Icon'

const WorkCard = props => {
  const { theme, clientType, title, stack, platforms, description, url, image, ...others } = props
  return (
    <Root theme={theme} {...others}>
      <ClientType color="primary">{clientType}</ClientType>
      <Title variant="h5">{title}</Title>
      <Desc variant="body3" theme={theme}>{description}</Desc>
      {((platforms && platforms.length) || (stack && stack.length)) && (
        <Lists>
          {platforms && platforms.length && (
            <List>
              <List_Title variant="caption2" theme={theme}>対応範囲</List_Title>
              <List_Parent>
                {platforms.map((name, index) => (
                  <List_Child key={index} variant="caption2" component="li">{name}</List_Child>
                ))}
              </List_Parent>
            </List>
          )}
          {stack && stack.length && (
            <List>
              <List_Title variant="caption2" theme={theme}>開発言語・ツール</List_Title>
              <List_Parent>
                {stack.map((name, index) => (
                  <StackList_Child key={index} variant="caption2" component="li" theme={theme}>{name}</StackList_Child>
                ))}
              </List_Parent>
            </List>
          )}
          {url && url.length && (
            <List>
              <URL theme={theme} variant="caption2" component="a" href={url} target="_blank" rel="noopener noreferrer"><Icon name="external-link" /><span> サイトを見る</span></URL>
            </List>
          )}
        </Lists>
      )}
    </Root>
  )
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 24px;
  border-top: 8px solid ${props => props.theme.scale[400]};
  background-color: ${props => props.theme.scale[800]};
  @media ${mq.and(mobile)} {
    padding: 16px;
  }
`

const ClientType = styled(Tag)`
  flex-grow: 0;
  flex-shrink: 0;
`

const Title = styled(Typography)`
  flex-grow: 0;
  flex-shrink: 0;
  margin-top: 20px;
  width: 100%;
`

const Desc = styled(Typography)`
  flex-grow: 1;
  flex-shrink: 1;
  margin-top: 12px;
  color: ${props => props.theme.scale[50]};
  width: 100%;
`

const Lists = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  margin-top: 24px;
  width: 100%;
`

const List = styled.div`
  &:not(:first-of-type) {
    margin-top: 2px;
  }
  display: flex;
`

const List_Title = styled(Typography)`
  flex-grow: 0;
  flex-shrink: 0;
  color: ${props => props.theme.scale[300]};
`

const List_Parent = styled.ul`
  margin-left: 8px;
  display: flex;
  flex-wrap: wrap;

  &::first-of-type {
    margin-left: 0;
  }
`

const List_Child = styled(Typography)`
  margin-right: 4px;
  &::after {
    content: ',';
  }
  &:last-of-type::after {
    content: '';
  }
`

const StackList_Child = styled(List_Child)`
  color: ${props => getThemeColor('primary', props.theme)};
`

const URL = styled(Typography)`
  color: ${props => props.theme.link};
  text-decoration: underline;
`

export default ThemeConsumer(WorkCard)
