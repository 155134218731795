import React from 'react'
import styled from '@emotion/styled'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import { getThemeColor, getThemeContrastColor } from '@/utils/themes'
import Typography from './Typography'

const Tag = props => {
  const { theme, color, type, scale, component, children, ...other } = props
  return (
    <Root
      theme={theme}
      color={color}
      type={type}
      scale={scale}
      {...other}>
      <Text
        variant="caption2"
        theme={theme}
        color={color}
        type={type}
        component={component}
        scale={scale}>
        {children}
      </Text>
    </Root>
  )
}

const getColor = props => {
  if (props.type === 'contrast') {
    return props.theme.text.contrast
  } else if (props.scale) {
    return props.theme.scale[props.scale]
  } else if (props.color) {
    return getThemeColor(props.color, props.theme)
  } else {
    return props.theme.text.default
  }
}

const getContrastColor = props => {
  if (props.type === 'contrast') {
    return props.theme.text.default
  } else if (props.scale) {
    return props.theme.scale.contrast[props.scale]
  } else if (props.color) {
    return getThemeContrastColor(props.color, props.theme)
  } else {
    return props.theme.text.contrast
  }
}

const Root = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0 16px;
  height: 24px;
  background-color: ${getColor};
  border-radius: 4px;
`

const Text = styled(Typography)`
  color: ${getContrastColor};
`

export default ThemeConsumer(Tag)
