import React, { Fragment } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { Location } from '@reach/router'
import PageBase from '@/components/base/Page'
import SEO from '@/components/head/seo'
import LD from '@/components/head/linking-data'
import LDEntity from '@/utils/linking-data'
import { mobile } from '@/utils/media'
import { mq } from '@/utils/helpers'
import PageTitle from '@/components/common/PageTitle'
import imgTitle from '@/images/title-works.png'
import List from '@/components/modules/Web/Work/List'
import Breadcrumb from '@/components/common/Breadcrumb.container'
import ContactLead from '@/components/modules/Contact/Lead'
import RecruitLead from '@/components/modules/Recruit/Lead'

const WorksIndexPage = (props) => (
  <StaticQuery
    query={query}
    render={(data) => {
      const siteMeta = data.site.siteMetadata
      const company = data.company.edges[0].node
      return (
        <Root>
          <Location>
            {({ location }) => {
              const type = new URLSearchParams(location.search).get('type')
              return (
                <Fragment>
                  <SEO
                    location={props.location}
                    title="WEB制作実績"
                    description="株式会社フロントビジョンのWEB制作実績の紹介。卓越した技術とイメージを共有できる技術者が、業務系からコンシューマ系まで、様々な分野の要望にお応えします。"
                  />
                  <LD
                    json={{
                      '@type': 'CreativeWork',
                      name: 'WEB制作実績一覧',
                      description:
                        '株式会社フロントビジョンのWEB制作実績の紹介。',
                      author: LDEntity.Corporation(siteMeta, company)
                    }}
                  />
                  <Head>
                    <PageTitle src={imgTitle}>WEB制作実績</PageTitle>
                  </Head>
                  <Main>
                    <Row>
                      <List
                        clientType={type}
                      />
                    </Row>
                    <Row css={styles.Breadcrumb}>
                      <Breadcrumb path={props.location.pathname} />
                    </Row>
                    <Row css={styles.Contact}>
                      <ContactLead />
                    </Row>
                    <Row css={styles.Recruit}>
                      <RecruitLead />
                    </Row>
                  </Main>
                </Fragment>
              )
            }}
          </Location>
        </Root>
      )
    }}
  />
)

const Root = styled.div``

const Head = styled.div``

const Main = styled.div``

const Row = styled.div`
  position: relative;
  padding: 64px 0;
  @media ${mq.and(mobile)} {
    padding: 32px 0;
  }
`

const styles = {
  PostList: css`
    padding: 0;
    @media ${mq.and(mobile)} {
      padding: 0;
    }
  `,
  Breadcrumb: css`
    padding: 0;
    @media ${mq.and(mobile)} {
      padding: 40px 0 0;
    }
  `,
  Contact: css`
    padding: 0;
    @media ${mq.and(mobile)} {
      padding: 0;
    }
  `,
  Recruit: css`
    padding: 0;
    @media ${mq.and(mobile)} {
      padding: 0;
    }
  `
}

export default PageBase(WorksIndexPage)

const query = graphql`
  query WorksPageQuery {
    site {
      siteMetadata {
        siteUrl
        title
        description
        keywords
        image
      }
    }
    company: allCompanyInfoYaml {
      edges {
        node {
          name
          legalName
          telephone
          email
          address {
            streetAddress
            addressLocality
            addressRegion
            postalCode
            addressCountry
          }
        }
      }
    }
  }
`
